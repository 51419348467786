import { render, staticRenderFns } from "./DesktopMenu.vue?vue&type=template&id=2345cc74&"
import script from "./DesktopMenu.vue?vue&type=script&lang=js&"
export * from "./DesktopMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconContact: require('/var/www/html/preview/components/icons/IconContact.vue').default,IconLocation: require('/var/www/html/preview/components/icons/IconLocation.vue').default,IconLang: require('/var/www/html/preview/components/icons/IconLang.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default})
