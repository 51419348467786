export default ({ i18n, $userAgent }) => {
    if ($userAgent.isWeb) {
        // States
        let initialized = false;
        let currentLocale = null;

        // Functions
        const isSupportedLocale = function (locale) {
            return ['ja'].includes(locale);
        }

        const getClientId = function (locale) {
            switch (locale) {
                case 'ja': return 'mA8zPyJSZRafDqJwv_c';
                default: return false;
            }
        }

        const initializeClient = function (locale) {
            const clientId = getClientId(locale);

            /* eslint-disable */
            // Copied the base from https://app.intelliticks.com/v2/settings/channels/website,
            // and replaced the client ID with {{clientId}}
            (function(I, L, T, i, c, k, s) {if(I.iticks) return;I.iticks = {host:c, settings:s, clientId:k, cdn:L, queue:[]};var h = T.head || T.documentElement;var e = T.createElement(i);var l = I.location;e.async = true;e.src = (L||c)+'/client/inject-v2.min.js';h.insertBefore(e, h.firstChild);I.iticks.call = function(a, b) {I.iticks.queue.push([a, b]);};})(window, 'https://cdn.intelliticks.com/prod/common', document, 'script', 'https://app.intelliticks.com', clientId, {});
            /* eslint-enable */

            initialized = true;
            currentLocale = locale;
        }

        const switchClient = function (oldLocale, newLocale) {
            const oldClientId = getClientId(oldLocale);
            const newClientId = getClientId(newLocale);

            if (oldClientId !== newClientId) {
                const iframe = document.querySelector('.iticks-frame-container > iframe');
                iframe.src = iframe.src.replaceAll(oldClientId, newClientId);

                currentLocale = newLocale;
            }
        }

        // Bind event handlers
        i18n.onBeforeLanguageSwitch = function (oldLocale, newLocale, isInitialSetup, context) {
            // Somehow `isInitialSetup` isn't working on app load, and `context.i18n` doesn't reflect the new locale
            // TODO: Check if `context.i18n` has been fixed since nuxt-i18n 6.27.2
            if (isInitialSetup || !isSupportedLocale(newLocale)) {
                return;
            }

            if (initialized) {
                if (currentLocale !== newLocale) {
                    switchClient(currentLocale, newLocale);
                }
            } else {
                initializeClient(newLocale);
            }
        }

        // As an alternative because `isInitialSetup` isn't working
        if (isSupportedLocale(i18n.locale)) {
            initializeClient(i18n.locale);
        }
    }
}
