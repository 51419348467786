import { render, staticRenderFns } from "./NavIcons.vue?vue&type=template&id=3d932b24&"
import script from "./NavIcons.vue?vue&type=script&lang=js&"
export * from "./NavIcons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconContact: require('/var/www/html/preview/components/icons/IconContact.vue').default,IconLocation: require('/var/www/html/preview/components/icons/IconLocation.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default,IconLang: require('/var/www/html/preview/components/icons/IconLang.vue').default,LocalePopup: require('/var/www/html/preview/components/LocalePopup.vue').default,Button: require('/var/www/html/preview/components/Button.vue').default,IconMenuClose: require('/var/www/html/preview/components/icons/IconMenuClose.vue').default,IconMenuOpen: require('/var/www/html/preview/components/icons/IconMenuOpen.vue').default})
