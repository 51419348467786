import { render, staticRenderFns } from "./AppNav.vue?vue&type=template&id=47eb2b3c&"
import script from "./AppNav.vue?vue&type=script&lang=js&"
export * from "./AppNav.vue?vue&type=script&lang=js&"
import style0 from "./AppNav.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNavOverlay: require('/var/www/html/preview/components/appnav/AppNavOverlay.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default,NavIcons: require('/var/www/html/preview/components/appnav/NavIcons.vue').default})
