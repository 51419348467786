import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_useragent_503e3cde from 'nuxt_plugin_useragent_503e3cde' // Source: ../plugins/user-agent.js (mode: 'all')
import nuxt_plugin_plugin_0d6c355b from 'nuxt_plugin_plugin_0d6c355b' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_f6c2052c from 'nuxt_plugin_pluginutils_f6c2052c' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_e2544f96 from 'nuxt_plugin_pluginrouting_e2544f96' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_aff7f14c from 'nuxt_plugin_pluginmain_aff7f14c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_7bf15e1e from 'nuxt_plugin_axios_7bf15e1e' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_fontawesome_e782291a from 'nuxt_plugin_fontawesome_e782291a' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_datefns_5672773b from 'nuxt_plugin_datefns_5672773b' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_chatbotclient_758c6452 from 'nuxt_plugin_chatbotclient_758c6452' // Source: ../plugins/chatbot.client.js (mode: 'client')
import nuxt_plugin_contentful_0fea6d46 from 'nuxt_plugin_contentful_0fea6d46' // Source: ../plugins/contentful.js (mode: 'all')
import nuxt_plugin_embedsocialclient_122b87a4 from 'nuxt_plugin_embedsocialclient_122b87a4' // Source: ../plugins/embedsocial.client.js (mode: 'client')
import nuxt_plugin_scroll_ee90a558 from 'nuxt_plugin_scroll_ee90a558' // Source: ../plugins/scroll.js (mode: 'all')
import nuxt_plugin_spiral_10db32a0 from 'nuxt_plugin_spiral_10db32a0' // Source: ../plugins/spiral.js (mode: 'server')
import nuxt_plugin_swiper_68e7f06e from 'nuxt_plugin_swiper_68e7f06e' // Source: ../plugins/swiper.js (mode: 'all')
import nuxt_plugin_utils_1ea7651c from 'nuxt_plugin_utils_1ea7651c' // Source: ../plugins/utils.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":(titleChunk) => {
      const appName = 'Shiseido Professional';
      const separator = ' | ';

      if (titleChunk) {
        titleChunk = titleChunk.replaceAll('<br>', ' ');
      }

      if (!titleChunk) {
        return appName;
      } else if (titleChunk.endsWith(separator + appName)) {
        return titleChunk;
      } else {
        return titleChunk + separator + appName;
      }
    },"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"robots","name":"robots","content":"none"},{"hid":"og:site_name","property":"og:site_name","content":"Shiseido Professional"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Fuse.typekit.net\u002Fzcx2miv.css"}],"htmlAttrs":{"data-navbar-transparent":false},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_useragent_503e3cde === 'function') {
    await nuxt_plugin_useragent_503e3cde(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0d6c355b === 'function') {
    await nuxt_plugin_plugin_0d6c355b(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_f6c2052c === 'function') {
    await nuxt_plugin_pluginutils_f6c2052c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_e2544f96 === 'function') {
    await nuxt_plugin_pluginrouting_e2544f96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_aff7f14c === 'function') {
    await nuxt_plugin_pluginmain_aff7f14c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7bf15e1e === 'function') {
    await nuxt_plugin_axios_7bf15e1e(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_e782291a === 'function') {
    await nuxt_plugin_fontawesome_e782291a(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_5672773b === 'function') {
    await nuxt_plugin_datefns_5672773b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_chatbotclient_758c6452 === 'function') {
    await nuxt_plugin_chatbotclient_758c6452(app.context, inject)
  }

  if (typeof nuxt_plugin_contentful_0fea6d46 === 'function') {
    await nuxt_plugin_contentful_0fea6d46(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_embedsocialclient_122b87a4 === 'function') {
    await nuxt_plugin_embedsocialclient_122b87a4(app.context, inject)
  }

  if (typeof nuxt_plugin_scroll_ee90a558 === 'function') {
    await nuxt_plugin_scroll_ee90a558(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_spiral_10db32a0 === 'function') {
    await nuxt_plugin_spiral_10db32a0(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_68e7f06e === 'function') {
    await nuxt_plugin_swiper_68e7f06e(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_1ea7651c === 'function') {
    await nuxt_plugin_utils_1ea7651c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
