import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=3b94ef86&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterActionShortcutSection: require('/var/www/html/preview/components/footer/ActionShortcutSection.vue').default,FooterScrollToTopBar: require('/var/www/html/preview/components/footer/ScrollToTopBar.vue').default,FooterDesktopMenu: require('/var/www/html/preview/components/footer/DesktopMenu.vue').default,FooterMobileMenu: require('/var/www/html/preview/components/footer/MobileMenu.vue').default,LocalePopup: require('/var/www/html/preview/components/LocalePopup.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default,Footer: require('/var/www/html/preview/components/footer/Footer.vue').default})
