import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: true,
  locales: [{"code":"en","alias":"en","iso":"en","file":"en.js","name":"English","region":"Global"},{"code":"en-HK","alias":"en_hk","iso":"en-HK","file":"en-HK.js","name":"English","region":"Hong Kong"},{"code":"zh-Hant-HK","alias":"zh_hk","iso":"zh-Hant-HK","file":"zh-HK.js","name":"繁體中文","region":"Hong Kong"},{"code":"id","alias":"en_id","iso":"en-ID","file":"id.js","name":"English","region":"Indonesia"},{"code":"ja","alias":"ja_jp","iso":"ja","file":"ja.js","name":"日本語","region":"Japan"},{"code":"ko","alias":"ko_kr","iso":"ko","file":"ko.js","name":"한국어","region":"Korea"},{"code":"ms","alias":"en_my","iso":"en-MY","file":"ms.js","name":"English","region":"Malaysia"},{"code":"en-SG","alias":"en_sg","iso":"en-SG","file":"en-SG.js","name":"English","region":"Singapore"},{"code":"zh-Hant-TW","alias":"zh_tw","iso":"zh-Hant-TW","file":"zh-TW.js","name":"繁體中文","region":"Taiwan"},{"code":"th","alias":"th_th","iso":"th","file":"th.js","name":"ไทย","region":"Thailand"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://preview.shiseido-professional.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","alias":"en","iso":"en","file":"en.js","name":"English","region":"Global"},{"code":"en-HK","alias":"en_hk","iso":"en-HK","file":"en-HK.js","name":"English","region":"Hong Kong"},{"code":"zh-Hant-HK","alias":"zh_hk","iso":"zh-Hant-HK","file":"zh-HK.js","name":"繁體中文","region":"Hong Kong"},{"code":"id","alias":"en_id","iso":"en-ID","file":"id.js","name":"English","region":"Indonesia"},{"code":"ja","alias":"ja_jp","iso":"ja","file":"ja.js","name":"日本語","region":"Japan"},{"code":"ko","alias":"ko_kr","iso":"ko","file":"ko.js","name":"한국어","region":"Korea"},{"code":"ms","alias":"en_my","iso":"en-MY","file":"ms.js","name":"English","region":"Malaysia"},{"code":"en-SG","alias":"en_sg","iso":"en-SG","file":"en-SG.js","name":"English","region":"Singapore"},{"code":"zh-Hant-TW","alias":"zh_tw","iso":"zh-Hant-TW","file":"zh-TW.js","name":"繁體中文","region":"Taiwan"},{"code":"th","alias":"th_th","iso":"th","file":"th.js","name":"ไทย","region":"Thailand"}],
  localeCodes: ["en","en-HK","zh-Hant-HK","id","ja","ko","ms","en-SG","zh-Hant-TW","th"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "n",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "e",
  27: "n",
  28: "-",
  29: "H",
  30: "K",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "a",
  42: "n",
  43: "g",
  44: "/",
  45: "e",
  46: "n",
  47: "-",
  48: "H",
  49: "K",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "z",
  57: "h",
  58: "-",
  59: "H",
  60: "K",
  61: ".",
  62: "j",
  63: "s",
  64: "\"",
  65: ":",
  66: "\"",
  67: ".",
  68: ".",
  69: "/",
  70: "l",
  71: "a",
  72: "n",
  73: "g",
  74: "/",
  75: "z",
  76: "h",
  77: "-",
  78: "H",
  79: "K",
  80: ".",
  81: "j",
  82: "s",
  83: "\"",
  84: ",",
  85: "\"",
  86: "i",
  87: "d",
  88: ".",
  89: "j",
  90: "s",
  91: "\"",
  92: ":",
  93: "\"",
  94: ".",
  95: ".",
  96: "/",
  97: "l",
  98: "a",
  99: "n",
  100: "g",
  101: "/",
  102: "i",
  103: "d",
  104: ".",
  105: "j",
  106: "s",
  107: "\"",
  108: ",",
  109: "\"",
  110: "j",
  111: "a",
  112: ".",
  113: "j",
  114: "s",
  115: "\"",
  116: ":",
  117: "\"",
  118: ".",
  119: ".",
  120: "/",
  121: "l",
  122: "a",
  123: "n",
  124: "g",
  125: "/",
  126: "j",
  127: "a",
  128: ".",
  129: "j",
  130: "s",
  131: "\"",
  132: ",",
  133: "\"",
  134: "k",
  135: "o",
  136: ".",
  137: "j",
  138: "s",
  139: "\"",
  140: ":",
  141: "\"",
  142: ".",
  143: ".",
  144: "/",
  145: "l",
  146: "a",
  147: "n",
  148: "g",
  149: "/",
  150: "k",
  151: "o",
  152: ".",
  153: "j",
  154: "s",
  155: "\"",
  156: ",",
  157: "\"",
  158: "m",
  159: "s",
  160: ".",
  161: "j",
  162: "s",
  163: "\"",
  164: ":",
  165: "\"",
  166: ".",
  167: ".",
  168: "/",
  169: "l",
  170: "a",
  171: "n",
  172: "g",
  173: "/",
  174: "m",
  175: "s",
  176: ".",
  177: "j",
  178: "s",
  179: "\"",
  180: ",",
  181: "\"",
  182: "e",
  183: "n",
  184: "-",
  185: "S",
  186: "G",
  187: ".",
  188: "j",
  189: "s",
  190: "\"",
  191: ":",
  192: "\"",
  193: ".",
  194: ".",
  195: "/",
  196: "l",
  197: "a",
  198: "n",
  199: "g",
  200: "/",
  201: "e",
  202: "n",
  203: "-",
  204: "S",
  205: "G",
  206: ".",
  207: "j",
  208: "s",
  209: "\"",
  210: ",",
  211: "\"",
  212: "z",
  213: "h",
  214: "-",
  215: "T",
  216: "W",
  217: ".",
  218: "j",
  219: "s",
  220: "\"",
  221: ":",
  222: "\"",
  223: ".",
  224: ".",
  225: "/",
  226: "l",
  227: "a",
  228: "n",
  229: "g",
  230: "/",
  231: "z",
  232: "h",
  233: "-",
  234: "T",
  235: "W",
  236: ".",
  237: "j",
  238: "s",
  239: "\"",
  240: ",",
  241: "\"",
  242: "t",
  243: "h",
  244: ".",
  245: "j",
  246: "s",
  247: "\"",
  248: ":",
  249: "\"",
  250: ".",
  251: ".",
  252: "/",
  253: "l",
  254: "a",
  255: "n",
  256: "g",
  257: "/",
  258: "t",
  259: "h",
  260: ".",
  261: "j",
  262: "s",
  263: "\"",
  264: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'en-HK.js': () => import('../../lang/en-HK.js' /* webpackChunkName: "lang-en-HK.js" */),
  'zh-HK.js': () => import('../../lang/zh-HK.js' /* webpackChunkName: "lang-zh-HK.js" */),
  'id.js': () => import('../../lang/id.js' /* webpackChunkName: "lang-id.js" */),
  'ja.js': () => import('../../lang/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'ko.js': () => import('../../lang/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'ms.js': () => import('../../lang/ms.js' /* webpackChunkName: "lang-ms.js" */),
  'en-SG.js': () => import('../../lang/en-SG.js' /* webpackChunkName: "lang-en-SG.js" */),
  'zh-TW.js': () => import('../../lang/zh-TW.js' /* webpackChunkName: "lang-zh-TW.js" */),
  'th.js': () => import('../../lang/th.js' /* webpackChunkName: "lang-th.js" */),
}
